@font-face {
  font-family: "Montserrat";
  src: url("./assets/fonts/Montserrat-Medium.eot");
  src: url("./assets/fonts/Montserrat-Medium.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/Montserrat-Medium.woff2") format("woff2"),
    url("./assets/fonts/Montserrat-Medium.woff") format("woff"),
    url("./assets/fonts/Montserrat-Medium.ttf") format("truetype"),
    url("./assets/fonts/Montserrat-Medium.svg#Montserrat-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("./assets/fonts/Montserrat-Regular.eot");
  src: url("./assets/fonts/Montserrat-Regular.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/Montserrat-Regular.woff2") format("woff2"),
    url("./assets/fonts/Montserrat-Regular.woff") format("woff"),
    url("./assets/fonts/Montserrat-Regular.ttf") format("truetype"),
    url("./assets/fonts/Montserrat-Regular.svg#Montserrat-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("./assets/fonts/Montserrat-ExtraBold.eot");
  src: url("./assets/fonts/Montserrat-ExtraBold.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/Montserrat-ExtraBold.woff2") format("woff2"),
    url("./assets/fonts/Montserrat-ExtraBold.woff") format("woff"),
    url("./assets/fonts/Montserrat-ExtraBold.ttf") format("truetype"),
    url("./assets/fonts/Montserrat-ExtraBold.svg#Montserrat-ExtraBold") format("svg");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("./assets/fonts/Montserrat-Black.eot");
  src: url("./assets/fonts/Montserrat-Black.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/Montserrat-Black.woff2") format("woff2"),
    url("./assets/fonts/Montserrat-Black.woff") format("woff"),
    url("./assets/fonts/Montserrat-Black.ttf") format("truetype"),
    url("./assets/fonts/Montserrat-Black.svg#Montserrat-Black") format("svg");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("./assets/fonts/Montserrat-Bold.eot");
  src: url("./assets/fonts/Montserrat-Bold.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/Montserrat-Bold.woff2") format("woff2"),
    url("./assets/fonts/Montserrat-Bold.woff") format("woff"),
    url("./assets/fonts/Montserrat-Bold.ttf") format("truetype"),
    url("./assets/fonts/Montserrat-Bold.svg#Montserrat-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("./assets/fonts/Montserrat-SemiBold.eot");
  src: url("./assets/fonts/Montserrat-SemiBold.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/Montserrat-SemiBold.woff2") format("woff2"),
    url("./assets/fonts/Montserrat-SemiBold.woff") format("woff"),
    url("./assets/fonts/Montserrat-SemiBold.ttf") format("truetype"),
    url("./assets/fonts/Montserrat-SemiBold.svg#Montserrat-SemiBold") format("svg");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("./assets/fonts/Montserrat-Light.eot");
  src: url("./assets/fonts/Montserrat-Light.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/Montserrat-Light.woff2") format("woff2"),
    url("./assets/fonts/Montserrat-Light.woff") format("woff"),
    url("./assets/fonts/Montserrat-Light.ttf") format("truetype"),
    url("./assets/fonts/Montserrat-Light.svg#Montserrat-Light") format("svg");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
}

.container {
  margin: 0 auto;
  max-width: 1200px;
  padding: 0 15px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600 !important;
  margin-bottom: unset !important;
}


/* NOTIFICATION */
.ant-notification-notice {
  width: 320px !important;
  background: #333 !important;
  color: #fff !important;
}

.ant-notification-notice-close,
.ant-notification-notice-close:hover {
  color: #fff !important;
}

.ant-notification-notice-with-icon .ant-notification-notice-description {
  font-size: 13px !important;
}

.ant-notification-notice-icon {
  font-size: 20px !important;
}