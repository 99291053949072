@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);
.loaderStyle {
  height: 150px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

@font-face {
  font-family: "Montserrat";
  src: url(/static/media/Montserrat-Medium.dadb1e02.eot);
  src: url(/static/media/Montserrat-Medium.dadb1e02.eot?#iefix) format("embedded-opentype"),
    url(/static/media/Montserrat-Medium.c416eeae.woff2) format("woff2"),
    url(/static/media/Montserrat-Medium.1415d3ef.woff) format("woff"),
    url(/static/media/Montserrat-Medium.b2e5fcde.ttf) format("truetype"),
    url(/static/media/Montserrat-Medium.f0af5dc8.svg#Montserrat-Medium) format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url(/static/media/Montserrat-Regular.36b1f4d4.eot);
  src: url(/static/media/Montserrat-Regular.36b1f4d4.eot?#iefix) format("embedded-opentype"),
    url(/static/media/Montserrat-Regular.518803d3.woff2) format("woff2"),
    url(/static/media/Montserrat-Regular.498c8cdb.woff) format("woff"),
    url(/static/media/Montserrat-Regular.d4f770a4.ttf) format("truetype"),
    url(/static/media/Montserrat-Regular.2c3ed57a.svg#Montserrat-Regular) format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url(/static/media/Montserrat-ExtraBold.8852943d.eot);
  src: url(/static/media/Montserrat-ExtraBold.8852943d.eot?#iefix) format("embedded-opentype"),
    url(/static/media/Montserrat-ExtraBold.95c2242b.woff2) format("woff2"),
    url(/static/media/Montserrat-ExtraBold.666df37f.woff) format("woff"),
    url(/static/media/Montserrat-ExtraBold.1523a896.ttf) format("truetype"),
    url(/static/media/Montserrat-ExtraBold.359d7be3.svg#Montserrat-ExtraBold) format("svg");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url(/static/media/Montserrat-Black.ca2c299f.eot);
  src: url(/static/media/Montserrat-Black.ca2c299f.eot?#iefix) format("embedded-opentype"),
    url(/static/media/Montserrat-Black.518c6d83.woff2) format("woff2"),
    url(/static/media/Montserrat-Black.b45e5825.woff) format("woff"),
    url(/static/media/Montserrat-Black.41a74f58.ttf) format("truetype"),
    url(/static/media/Montserrat-Black.0401996f.svg#Montserrat-Black) format("svg");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url(/static/media/Montserrat-Bold.78999d96.eot);
  src: url(/static/media/Montserrat-Bold.78999d96.eot?#iefix) format("embedded-opentype"),
    url(/static/media/Montserrat-Bold.dbf9cc3b.woff2) format("woff2"),
    url(/static/media/Montserrat-Bold.0329bf8f.woff) format("woff"),
    url(/static/media/Montserrat-Bold.cfaa6b88.ttf) format("truetype"),
    url(/static/media/Montserrat-Bold.54416961.svg#Montserrat-Bold) format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url(/static/media/Montserrat-SemiBold.417641b3.eot);
  src: url(/static/media/Montserrat-SemiBold.417641b3.eot?#iefix) format("embedded-opentype"),
    url(/static/media/Montserrat-SemiBold.53b31a44.woff2) format("woff2"),
    url(/static/media/Montserrat-SemiBold.fa5f925d.woff) format("woff"),
    url(/static/media/Montserrat-SemiBold.feb9a9ab.ttf) format("truetype"),
    url(/static/media/Montserrat-SemiBold.6e9de36d.svg#Montserrat-SemiBold) format("svg");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url(/static/media/Montserrat-Light.8ee066d1.eot);
  src: url(/static/media/Montserrat-Light.8ee066d1.eot?#iefix) format("embedded-opentype"),
    url(/static/media/Montserrat-Light.f7bb2606.woff2) format("woff2"),
    url(/static/media/Montserrat-Light.05c8ae97.woff) format("woff"),
    url(/static/media/Montserrat-Light.6bff2760.ttf) format("truetype"),
    url(/static/media/Montserrat-Light.17761eb3.svg#Montserrat-Light) format("svg");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
}

.container {
  margin: 0 auto;
  max-width: 1200px;
  padding: 0 15px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600 !important;
  margin-bottom: unset !important;
}


/* NOTIFICATION */
.ant-notification-notice {
  width: 320px !important;
  background: #333 !important;
  color: #fff !important;
}

.ant-notification-notice-close,
.ant-notification-notice-close:hover {
  color: #fff !important;
}

.ant-notification-notice-with-icon .ant-notification-notice-description {
  font-size: 13px !important;
}

.ant-notification-notice-icon {
  font-size: 20px !important;
}
